import { useLocation } from 'react-router-dom';
import Url from 'url-parse';

/**
 * Generates url to provide to the `to` attribute of React Router DOM's
 * `Link` component.
 *
 * @function constructURL
 * @param {string} to Absolute path with querystring and/or hash/fragments.
 * @param {object} [config]
 * @param {boolean} [config.ignoreExistingHash] When true, removes all existing hash params first.
 * @param {boolean} [config.ignoreExistingQueryString] When true, removes all existing search params first.
 * @returns {string} Returns a new url
 * @example Usage examples:
 * // Sets a property on the querystring leaving any existing ones
 * <Link to={constructURL('?myqsprop=myvalue')}>My Link</a>
 *
 * // Sets a property on the querystring removing any existing ones
 * <Link to={constructURL('?myqsprop=myvalue', { replaceExisting: true })}>My Link</a>
 *
 * // Sets the path, the querystring and the hash preserving any existing ones
 * <Link to={constructURL('/another-page?myqsprop=myvalue#fragment-value', { replaceExisting: true })}>My Link</a>
 */

export const useConstructURL = () => {
  const { pathname, search, hash } = useLocation();
  const location = { pathname, search, hash }; //aligned with Path interface from react router

  const constructURL = (to, config = {}) => {
    if (!to) {
      return location;
    }

    //todo63: check with v6 docs how to manage state
    const { ignoreExistingHash = false, ignoreExistingQueryString = false, state = {} } = config;
    const a = new Url(to, {}, true);

    const search = new URLSearchParams(ignoreExistingQueryString ? '' : location.search);
    const givenSearchParams = new URLSearchParams(a.query);

    givenSearchParams.forEach((value, key) => {
      if (value) {
        search.set(key, value);
      } else {
        search.delete(key);
      }
    });

    const hash = a.hash || ignoreExistingHash ? a.hash : location.hash;
    const pathname = a.pathname || location.pathname;

    return {
      ...location,
      hash,
      state: { ...location.state, ...state },
      pathname,
      search: `?${search.toString().replace(/%7C/g, '|')}`,
    };
  };

  return constructURL;
};
