import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useOnlineStatus from '@rehooks/online-status';
import { useCategoryRouteValidator } from 'Utils/useCategoryRouteValidator';
import { SSRContext } from 'Utils/SSRContext';
import loadable from '@loadable/component';
import { isCurrentPath } from '../../utils/appRoutes';
import { ARD_PERMA_SEARCH, ARD_SEARCH } from '../../utils/appRoutes.definitions';

const NotFoundPage = loadable(() => import('../NotFoundPage/NotFoundPage'));
const OfflinePage = loadable(() => import('../OfflinePage/OfflinePage'));

export const SRPWrapper = ({ children }) => {
  const online = useOnlineStatus();
  const location = useLocation();
  const { category, subCategory, subSubCategory, slug, uberfilter } = useParams();
  const categoryPath = [category, subCategory, subSubCategory].filter(Boolean)?.join('/');
  const { isValidCategorySSR, SSRPath } = useContext(SSRContext);
  const isSRP = isCurrentPath(ARD_SEARCH, location) || isCurrentPath([ARD_PERMA_SEARCH, slug, uberfilter], location);
  const isValidCategory = useCategoryRouteValidator(isValidCategorySSR, categoryPath, SSRPath);

  const get404Page = () => {
    if (!online) return <OfflinePage />;
    return <NotFoundPage />;
  };

  if (isSRP) return children;
  if (isValidCategory === false) {
    return get404Page();
  }
  return children;
};
